import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import TeacherCard from './TeacherCard';

const TeachersData = () => {
    const { dept } = useParams()
    const [teachers, setTeachers] = useState([]);
    useEffect(() => {
        fetch('https://fahimahammed.github.io/test-data/data/teachersData.json')
            .then(res => res.json())
            .then(data => (dept === 'all') ? setTeachers(data) : setTeachers((data.filter(item => item.dept === dept))))
    }, [dept])
    console.log(teachers)
    return (
        <div className='container mx-auto my-12'>
            <div className="drawer drawer-mobile">
                <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content flex flex-col">


                    <label for="my-drawer-2" className="btn btn-primary drawer-button lg:hidden"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg></label>
                    <h1 className='text-3xl font-bold text-center uppercase'>Teacher's <span className='text-primary'>Profile</span></h1>
                    <div className='flex justify-center'>
                        {
                            (dept !== 'all') && <h1 className='my-4 font-bold text-xl border w-56  text-center bg-primary text-white px-4 py-2 rounded-full'>Dept of {dept}</h1>
                        }
                    </div>
                    <div className='flex flex-wrap gap-8 justify-center mt-4'>
                        {
                            teachers && teachers.map(teacher => <TeacherCard key={teacher._id} data={teacher} />)
                        }
                    </div>
                </div>
                <div className="drawer-side border-r-2">
                    <label for="my-drawer-2" className="drawer-overlay"></label>
                    <ul className="menu p-4 overflow-y-auto w-52 sm:w-64 bg-base-100 text-secondary">

                        <Link to='/teachers/all'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>All Teachers</p></li></Link>
                        <Link to='/teachers/CSE'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Dept of CSE</p></li></Link>
                        <Link to='/teachers/EEE'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Dept of EEE</p></li></Link>
                        <Link to='/teachers/CE'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Dept of CE</p></li></Link>
                        <Link to='/teachers/TE'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Dept of TE</p></li></Link>
                        <Link to='/teachers/ME'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Dept of ME</p></li></Link>
                        <Link to='/teachers/Mathematics'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Dept of Math</p></li></Link>
                        <Link to='/teachers/Physics'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Dept of Physics</p></li></Link>
                        <Link to='/teachers/Chemistry'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Dept of Chemistry</p></li></Link>
                        <Link to='/teachers/Accounting'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Dept of Accounting</p></li></Link>
                        <Link to='/teachers/Statistics'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Dept of Statistics</p></li></Link>
                        <Link to='/teachers/Economics'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Dept of Econimics</p></li></Link>

                    </ul>

                </div>
            </div>
        </div>
    );
};

export default TeachersData;