import { faEnvelopeOpen, faLocationDot, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import SocialIcons from '../shared/SocialIcons';

const Contact = () => {
    return (
        <div>
            <div>
                <div className='page-header h-72 block'>
                    <h1 className='text-3xl md:text-5xl font-bold text-white uppercase'>Contact Info</h1>
                </div>
                <div className='my-12 container mx-auto'>
                    <p className='text-primary font-bold text-xl text-center'>You can always contact with us via email or phone. Get in touch with contact and get a quote form.</p>
                    <div className='flex flex-wrap justify-center gap-4 md:gap-12 my-12'>
                        <div className='text-center'>
                            <FontAwesomeIcon className='text-primary p-4 border border-primary rounded-full w-8 h-8' icon={faLocationDot} />
                            <h1 className='text-xl font-bold text-primary py-1'>Address</h1>
                            <h1>Jessore Road, Boikali, Khulna-9000</h1>
                        </div>
                        <div className='text-center'>
                            <FontAwesomeIcon className='text-primary p-4 border border-primary rounded-full w-8 h-8' icon={faPhoneAlt} />
                            <h1 className='text-xl font-bold text-primary py-1'>Phone/Mobile</h1>
                            <h1>041-763509</h1>
                            <h1>01711383993, 01711383995</h1>
                        </div>
                        <div className='text-center'>
                            <FontAwesomeIcon className='text-primary p-4 border border-primary rounded-full w-8 h-8' icon={faEnvelopeOpen} />
                            <h1 className='text-xl font-bold text-primary py-1'>Email</h1>
                            <h1>chairman@imperial.edu.bd</h1>
                        </div>
                    </div>
                    <div className='text-center mt-20'>
                        <h1 className='text-xl mb-4'>Find us on social media</h1>
                        <SocialIcons/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;