import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const FacilitiesCard = ({data}) => {
    const {title, description, icon} = data;
    return (
        <div className="card card-side w-80 bg-base-100 rounded-none">
            <figure className='w-48 h-32'><FontAwesomeIcon className='text-primary' icon={icon} size='10x'/></figure>
            <div className="card-body">
                <h2 className="card-title">{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default FacilitiesCard;