import React from 'react';

const Library = () => {
    return (
        <div>
            <div className='page-header h-72 block'>
                <div>

                    <h1 className='text-3xl md:text-5xl font-bold text-white uppercase text-center'>Library</h1>
                </div>
            </div>
            <div className='container mx-auto px-2 flex flex-wrap my-16'>
                <div className='w-fit md:w-1/2 '>
                    <img src="https://i.ibb.co/QfyTMR2/diagnostic-lab-hand-holding-tools.jpg" alt="Library" />
                </div>
                <div className='w-fit md:w-1/2 pl-auto sm:pl-8 my-4 md:my-auto'>
                    <p className='text-primary font-bold'>The Imperial College has a well-equipped air-conditioned library with 16,000 textbooks and 2,000 reference books</p>
                    <p className='py-4'>Library plays the key role to provide the knowlwdge-based support to faculty members, researchers, students and all kind of institutional users. ICE has a ricch library which is open for all with good collection of books, journals, newsletter, thesis works and CDs.</p>
                </div>
            </div>
        </div>
    );
};

export default Library;