import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const NewsNoticeDetails = () => {
    const { catagory, id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch('https://fahimahammed.github.io/test-data/data/notice.json')
            .then(res => res.json())
            .then(data => setData(data.filter(item => (item.type === catagory && item._id === id))))
    }, [catagory, id])

    return (
        <div>
            <div className='text-center my-12'>
                <h1 className='text-xl md:text-3xl py-4 uppercase font-bold text-primary underline'>{data[0]?.type}</h1>
                <p>Date: {data[0]?.date}</p>
                <h1 className='text-xl md:text-2xl py-4'>{data[0]?.title}</h1>
                <img className='mx-auto' src={data[0]?.image} alt="newsNotice" />
                <h1>{data[0]?.text}</h1>
            </div>
        </div>
    );
};

export default NewsNoticeDetails;