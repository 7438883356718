import React, { useEffect, useState } from 'react';

const Gallery = () => {
    const [imgUrls, setImgUrls] = useState([]);
    useEffect(()=>{
        fetch('https://fahimahammed.github.io/test-data/data/gallary.json')
        .then(res => res.json())
        .then(data => setImgUrls(data))
        .catch(err => console.log(err))
    },[])
    return (
        <div className='my-16 container mx-auto'>
            <h1 className='text-center text-3xl text-secondary font-bold pb-12'>GALLERY</h1>
            <div style={{ columns: 4, columnGap: 1 }}>
                {
                    imgUrls.map((img, i) => <img key={i} src={img} alt="img" />)
                }
            </div>
        </div>
    );
};

export default Gallery;