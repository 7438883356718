import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const LinkCard = ({ data }) => {
    const { icon, title, path } = data;
    return (
        <Link to={path}>
            <div className="card w-60 h-56 shadow-xl bg-primary text-white shadow-2xl shadow-secondary hover:bg-secondary hover:shadow-primary">
                <figure className="px-10 pt-10">

                    <FontAwesomeIcon icon={icon} size='4x' />
                </figure>
                <div className="card-body items-center text-center">
                    <h2 className="card-title text-2xl font-semibold">{title}</h2>
                </div>
            </div>
        </Link>
    );
};

export default LinkCard;