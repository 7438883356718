import React from 'react';

const AdmissionBanner = () => {
    return (
        <div className='page-header h-72'>
            <h1 className='text-3xl md:text-5xl font-bold text-white text-center'>UNDERGRADUATE ADMISSION</h1>
        </div>
    );
};

export default AdmissionBanner;