import React from 'react';
import logo from '../../../assets/images/logo.png';
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <div className='shadow-lg'>
            <div className="container mx-auto py-2">

                <div className="navbar">
                    <div className="navbar-start">
                        <div className="dropdown">
                            <label tabIndex="0" className="btn btn-ghost lg:hidden">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
                            </label>
                            <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
                                <Link to='/'><li className="hover:text-primary hover:font-bold"><p>Home</p></li></Link>
                                <li tabIndex="0">
                                    <p className="hover:text-primary hover:font-bold">
                                        Administration
                                        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                    </p>
                                    <ul className="p-2 bg-white z-50">
                                        <Link to="/article/chairman"><li className="hover:text-primary hover:font-bold"><p>Chairman</p></li></Link>
                                        <Link to="/article/ed"><li className="hover:text-primary hover:font-bold"><p>Executive Director</p></li></Link>
                                        <Link to="/article/principal"><li className="hover:text-primary hover:font-bold"><p>Principal</p></li></Link>
                                        <Link to="/article/vice-principal"><li className="hover:text-primary hover:font-bold"><p>Vice Principal</p></li></Link>
                                        <Link to="/governing-body"><li className="hover:text-primary hover:font-bold"><p>Governing Body</p></li></Link>
                                        <Link to="/teachers/all"><li className="hover:text-primary hover:font-bold"><p>Faculty</p></li></Link>
                                    </ul>
                                </li>
                                <li tabIndex="0">
                                    <p className="hover:text-primary hover:font-bold">
                                        Departments
                                        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                    </p>
                                    <ul className="p-2 bg-white z-50">
                                        <Link to="/faculty/cse"><li className="hover:text-primary hover:font-bold"><p>Computer Science & Engineering</p></li></Link>
                                        <Link to="/faculty/ce"><li className="hover:text-primary hover:font-bold"><p>Civil Engineering</p></li></Link>
                                        <Link to="/faculty/te"><li className="hover:text-primary hover:font-bold"><p>Textile Engineering</p></li></Link>
                                        <Link to="/faculty/eee"><li className="hover:text-primary hover:font-bold"><p>Electrical & Electronics Engineering</p></li></Link>
                                        <Link to="/faculty/me"><li className="hover:text-primary hover:font-bold"><p>Mechanical Engineering</p></li></Link>
                                    </ul>
                                </li>
                                <li tabIndex="0">
                                    <p className="hover:text-primary hover:font-bold">
                                        Facilities
                                        <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                    </p>
                                    <ul className="p-2 bg-white z-50">
                                        <Link to="/modern-lab"><li className="hover:text-primary hover:font-bold"><p>Modern Lab</p></li></Link>
                                        <li className="hover:text-primary hover:font-bold"><p>Stipend & Scholarship</p></li>
                                        <Link to="/club-organization"><li className="hover:text-primary hover:font-bold"><p>Club & Organization</p></li></Link>
                                        <Link to='/library'><li className="hover:text-primary hover:font-bold"><p>Library</p></li></Link>
                                    </ul>
                                </li>

                                <Link to="/about-us"><li className="hover:text-primary hover:font-bold"><p>About us</p></li></Link>
                                <Link to="/contact-info"><li className="hover:text-primary hover:font-bold"><p>Contact</p></li></Link>
                                <Link to='/admission' className="btn btn-primary rounded-full text-white">Admission</Link>
                            </ul>
                        </div>
                        <Link to="/"><p className="w-52 md:w-auto"><img className="w-[180px] md:w-56" src={logo} alt="Logo" /></p></Link>
                    </div>
                    <div className="navbar-center hidden lg:flex">
                        <ul className="menu menu-horizontal p-0 text-secondary">
                            <Link to='/'><li className="hover:text-primary hover:font-bold"><p>Home</p></li></Link>
                            <li tabIndex="0">
                                <p className="hover:text-primary hover:font-bold">
                                    Administration
                                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                </p>
                                <ul className="p-2 bg-white z-50">
                                    <Link to="/article/chairman"><li className="hover:text-primary hover:font-bold"><p>Chairman</p></li></Link>
                                    <Link to="/article/ed"><li className="hover:text-primary hover:font-bold"><p>Executive Director</p></li></Link>
                                    <Link to="/article/principal"><li className="hover:text-primary hover:font-bold"><p>Principal</p></li></Link>
                                    <Link to="/article/vice-principal"><li className="hover:text-primary hover:font-bold"><p>Vice Principal</p></li></Link>
                                    <Link to="/governing-body"><li className="hover:text-primary hover:font-bold"><p>Governing Body</p></li></Link>
                                    <Link to="/teachers/all"><li className="hover:text-primary hover:font-bold"><p>Faculty</p></li></Link>
                                </ul>
                            </li>
                            <li tabIndex="0">
                                <p className="hover:text-primary hover:font-bold">
                                    Departments
                                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                </p>
                                <ul className="p-2 bg-white z-50">
                                    <Link to="/faculty/cse"><li className="hover:text-primary hover:font-bold"><p>Computer Science & Engineering</p></li></Link>
                                    <Link to="/faculty/ce"><li className="hover:text-primary hover:font-bold"><p>Civil Engineering</p></li></Link>
                                    <Link to="/faculty/te"><li className="hover:text-primary hover:font-bold"><p>Textile Engineering</p></li></Link>
                                    <Link to="/faculty/eee"><li className="hover:text-primary hover:font-bold"><p>Electrical & Electronics Engineering</p></li></Link>
                                    <Link to="/faculty/me"><li className="hover:text-primary hover:font-bold"><p>Mechanical Engineering</p></li></Link>
                                </ul>
                            </li>
                            <li tabIndex="0">
                                <p className="hover:text-primary hover:font-bold">
                                    Facilities
                                    <svg className="fill-current" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" /></svg>
                                </p>
                                <ul className="p-2 bg-white z-50">
                                    <Link to="/modern-lab"><li className="hover:text-primary hover:font-bold"><p>Modern Lab</p></li></Link>
                                    <li className="hover:text-primary hover:font-bold"><p>Stipend & Scholarship</p></li>
                                    <Link to="/club-organization"><li className="hover:text-primary hover:font-bold"><p>Club & Organization</p></li></Link>
                                    <Link to='/library'><li className="hover:text-primary hover:font-bold"><p>Library</p></li></Link>
                                </ul>
                            </li>

                            <Link to="/about-us"><li className="hover:text-primary hover:font-bold"><p>About us</p></li></Link>
                            <Link to="/contact-info"><li className="hover:text-primary hover:font-bold"><p>Contact</p></li></Link>
                        </ul>
                    </div>
                    <div className="navbar-end">
                        <div className="hidden md:block">
                            <Link to="/admission" className="btn btn-primary rounded-full text-white">Admission</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;