import { faCalendarDays, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const EventCard = ({ data }) => {
    const { title, description, time, date, regStatus, img } = data;
    return (
        <div className="card w-72 bg-base-100 shadow-xl rounded">
            <figure><img className='h-40 w-full' src={img} alt="Shoes" /></figure>
            <div className="card-body">
                <h2 className="card-title">
                    {title}
                    <div className={regStatus ? "badge badge-primary" : ""}>{regStatus ? "New" : ""}</div>
                </h2>
                <small className='text-justify' >{description.slice(0, 100)} ...</small>
                <div className='flex justify-between mt-auto'>
                    <small><FontAwesomeIcon className='text-primary' icon={faClock} /> {time}</small>
                    <small><FontAwesomeIcon className='text-primary' icon={faCalendarDays} /> {date}</small>
                </div>
                <div className="card-actions justify-end">

                    <div className={regStatus ? "badge badge-outline badge-primary" : "badge badge-outline badge-secondary"}>{regStatus ? "Regestration Open" : "Regestration Closed"}</div>
                </div>
            </div>
        </div>
    );
};

export default EventCard;