import React from 'react';
import { Link, Outlet } from 'react-router-dom';

const StudentCorner = () => {
    return (
        <div>
            <div className='container mx-auto my-12'>
                <div className="drawer drawer-mobile">
                    <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                    <div className="drawer-content flex flex-col">


                        <label for="my-drawer-2" className="btn btn-primary drawer-button lg:hidden"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg></label>
                        <h1 className='text-3xl font-bold text-center uppercase'>Students <span className='text-primary'>Corner</span></h1>
                        <div className='flex justify-center'>
                            <Outlet></Outlet>
                        </div>
                    </div>
                    <div className="drawer-side border-r-2">
                        <label for="my-drawer-2" className="drawer-overlay"></label>
                        <ul className="menu p-4 overflow-y-auto w-52 sm:w-64 bg-base-100 text-secondary">

                            <Link to='/student-corner/result'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Result</p></li></Link>
                            <Link to='/student-corner/exam-notice'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Exam Notice</p></li></Link>
                            <Link to='/student-corner/exam-routine'><li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Exam Routine</p></li></Link>
                            <li className='hover:text-primary hover:font-bold font-semibold text-md'><p>Others</p></li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentCorner;