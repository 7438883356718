import React from 'react';
import DeptCard from './DeptCard';

const deptData = [
    {
        "_id": "1",
        "name": "Computer Science & Engineering",
        "description": "Computer Science and Engineering (CSE) Discipline of ICE started its academic activities with only 20 undergraduate students...",
        "img": "https://i.ibb.co/DLxLK4T/cse-banner.jpg",
        "path": "/faculty/cse"
    },
    {
        "_id": "2",
        "name": "Electrical & Electronics Engineering",
        "description": "The EEE Department is one of the best departments of Imperial College of Engineering, Khulna and started its journey from the beginning...",
        "img": "https://i.ibb.co/N1j7jQs/EEE-banner.jpg",
        "path": "/faculty/eee"
    },
    {
        "_id": "3",
        "name": "Textile Engineering",
        "description": "The department’s facilitated with highly qualified, dynamic, dedicated and well experienced faculty members to build the best ...",
        "img": "https://i.ibb.co/3kb9tfk/textile-banner.jpg",
        "path": "/faculty/te"
    },
    {
        "_id": "4",
        "name": "Civil Engineering",
        "description": "Civil Engineering is the profession of construction, designing and executing structural works that serve the general public ... ",
        "img": "https://i.ibb.co/xmBns0T/civil-banner.jpg",
        "path": "/faculty/ce"
    },
    {
        "_id": "5",
        "name": "Mechanical Engineering",
        "description": "The ME Department is one of the best departments of Imperial College of Engineering and started its journey from the beginning of ... ",
        "img": "https://i.ibb.co/YNfwyZB/mechanical-banner.jpg",
        "path": "/faculty/me"
    }
]

const Departments = () => {
    return (
        <div className='bg-accent py-12' id="programs">
            <div className='container mx-auto'>
                <h1 className='text-3xl font-bold text-secondary text-center pb-4 mt-4'>OUR <span className='text-primary'> DEPARTMENTS</span></h1>
                <p>In the era of rapid integration & innovation of technology in every field of life, becoming an ENGINEERING graduate will give you the opportunity of high potential career growth.</p>
                <div className='flex flex-wrap gap-6 justify-center mt-4 mb-12'>
                    {
                        deptData.map(data => <DeptCard key={data._id} data={data}></DeptCard>)
                    }
                </div>
            </div>
        </div>
    );
};

export default Departments;