import React from 'react';

const LabCard = ({ data }) => {
    return (
        <div className="card card-compact w-72 bg-gray-100 shadow-xl">
            <figure><img src={data.labImage} alt="LabImage" /></figure>
            <div className="card-body">
                <h2 className="card-title">{data.labName}</h2>
            </div>
        </div>
    );
};

export default LabCard;