import React, { useEffect, useState } from 'react';

const MovingText = () => {
    const [text, setText] = useState({});
    useEffect(()=>{
        fetch('https://fahimahammed.github.io/test-data/data/movingtext.json')
        .then(res => res.json())
        .then(data => setText(data))
    },[])
    return (
        <div className='bg-secondary text-white py-1'>
            <p className='container mx-auto'><marquee>{text?.text}</marquee></p>
        </div>
    );
};

export default MovingText;