import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LabCard from './LabCard';

const Faculty = () => {
    const [facultyData, setFacultyData] = useState({});
    const { dept } = useParams();

    useEffect(() => {
        fetch('https://fahimahammed.github.io/test-data/data/facultyData.json')
            .then(res => res.json())
            .then(data => setFacultyData(data.find(item => (item.code === dept))))
    }, [dept])
    return (
            <section>
                <div className='page-header h-72'>
                    <h1 className='text-3xl md:text-5xl uppercase font-bold text-white text-center my-8'>{facultyData?.name}</h1>
                </div>

                <div className='container mx-auto'>
                    <div className='my-12 mx-4 text-justify'>
                        <p className='py-2'>{facultyData?.description1}</p>
                        <p className='py-2'>{facultyData?.description2}</p>
                        <p className='py-2'>{facultyData?.description3}</p>
                    </div>
                    <div>
                        <h1 className='text-3xl font-bold  text-center text-secondary'>LAB & <span className='text-primary'>INFRASTRUCTURE</span></h1>
                        <div className='flex flex-wrap gap-4 justify-center my-12'>
                            {
                                facultyData.labs && facultyData?.labs?.map(data => <LabCard key={data.labid} data={data} />)
                            }
                        </div>
                    </div>
                </div>
            </section>
    );
};

export default Faculty;