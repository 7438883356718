import React from 'react';

const Row = ({ data }) => {
    return (
        <tr>
            <td className='text-primary hover:underline'><a href={data.link}>{data.title}</a></td>
            <td>{data.date}</td>
        </tr>
    );
};

export default Row;