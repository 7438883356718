import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const Session = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    academy: "",
    department: "",
    roll: "",
    session: "",
    area: "",
    city: "",
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://server.thinkystorm.com/api/v1/registrations/registrations', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit form data using different e-mail');
      }

      toast.success("Registration successful!", {
        onClose: () => {
          // Redirect to overview page
          window.location.href = "/overview";
        }
      });
      
      setFormData({
        name: "",
        phone: "",
        email: "",
        academy: "",
        department: "",
        roll: "",
        session: "",
        area: "",
        city: "",
      });

    } catch (error) {
      console.error('Error:', error.message);
      toast.error("Failed to submit registration using different e-mail.");
    }
  };
  return (
    <div
      style={{ background: "linear-gradient(to right, #8309B2, #26043D)" }}
      className="min-h-screen text-white"
    >
      <div class="flex items-center justify-center p-12">
        <div class="mx-auto w-full max-w-[550px] ">
          <div>
            <h1 className=" text-xl font-bold pb-2 font-serif">
              Let's Code your Career through programming !!
            </h1>
          </div>
          <form onSubmit={handleSubmit}>
            <div class="mb-5">
              <label for="name" class="mb-3 block text-base font-medium ">
                Full Name
              </label>
              <input
                type="text"
                name="name"
                required
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Full Name"
                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div class="mb-5">
              <label for="phone" class="mb-3 block text-base font-medium ]">
                Phone Number
              </label>
              <input
                type="text"
                name="phone"
                required
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Enter your phone number"
                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div class="mb-5">
              <label for="email" class="mb-3 block text-base font-medium ">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Enter your email"
                class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
              />
            </div>
            <div class="-mx-3 flex flex-wrap">
              <div class="w-full px-3 sm:w-1/2">
                <div class="mb-5">
                  <label for="time" class="mb-3 block text-base font-medium ">
                    Academy{" "}
                  </label>
                  <select
                    name="academy"
                    required
                    value={formData.academy}
                    onChange={handleInputChange}
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  >
                    <option value="Bsc">Imperial</option>
                    <option value="Diploma">Mangrove</option>
                  </select>
                </div>
              </div>
              <div class="w-full px-3 sm:w-1/2">
                <div class="mb-5">
                  <label for="date" class="mb-3 block text-base font-medium ">
                    Department
                  </label>
                  <select
                    name="department"
                    value={formData.department}
                    required
                    onChange={handleInputChange}
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  >
                    <option value="CSE">CSE</option>
                    <option value="EEE">EEE</option>
                    <option value="Civil">Civil</option>
                    <option value="textile">Textile</option>
                    <option value="Mechanical">Mechanical</option>
                    <option value="Other Department">Other Department</option>
                  </select>
                </div>
              </div>
              <div class="w-full px-3 sm:w-1/2">
                <div class="mb-5">
                  <label for="time" class="mb-3 block text-base font-medium ">
                    Academic Roll
                  </label>
                  <input
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    type="text"
                    name="roll"
                    required
                    value={formData.roll}
                    onChange={handleInputChange}
                    placeholder="Enter Your Roll"
                  ></input>
                </div>
              </div>
              <div class="w-full px-3 sm:w-1/2">
                <div class="mb-5">
                  <label for="time" class="mb-3 block text-base font-medium ">
                    Session
                  </label>
                  <select
                    name="session"
                    value={formData.session}
                    required
                    onChange={handleInputChange}
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                  >
                    <option value="2017-2018">2017-2018</option>
                    <option value="2018-2019">2018-2019</option>
                    <option value="2019-2020">2019-2020</option>
                    <option value="2020-2021">2020-2021</option>
                    <option value="2021-2022">2021-2022</option>
                    <option value="2022-2023">2022-2023</option>
                    <option value="Other Session">Other Session</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="mb-5 pt-3">
              <label class="mb-5 block text-base font-semibold  sm:text-xl">
                Present Address Details
              </label>
              <div class="-mx-3 flex flex-wrap">
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <input
                      type="text"
                      name="area"
                      value={formData.area}
                      onChange={handleInputChange}
                      required
                      id="area"
                      placeholder="Enter area"
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
                <div class="w-full px-3 sm:w-1/2">
                  <div class="mb-5">
                    <input
                      type="text"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                      required
                      id="city"
                      placeholder="Enter city"
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
           
             
             <button
                type="submit"
                className="hover:shadow-form w-full rounded-md bg-gradient-to-r from-[#7431F6] via-[#9E0C9E] to-[#0D0518] py-3 px-8 text-center text-base font-semibold text-white outline-none"
              >
                Registration
              </button>
          
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Session;
