import { faBabyCarriage, faBook, faChalkboardUser, faLaptopCode, faPersonSkating, faShieldVirus } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import FacilitiesCard from './FacilitiesCard';
import building from '../../assets/images/building-2.jpg';

const facilitiesData = [
    {
        "_id": "1",
        "title": "Accommodation",
        "icon": faShieldVirus,
        "description": "We are providing a secure and convenient living facility for our students. DIU dormitories are a home away from home"
    },
    {
        "_id": "2",
        "title": "Transportation",
        "icon": faBabyCarriage,
        "description": "To make the student’s life easier and safe, we are providing 50+ buses from different points of Dhaka city to t"
    },
    {
        "_id": "3",
        "title": "Library",
        "icon": faBook,
        "description": "DIU library has a collection of over 50000 books, journals, research papers and enriching the resources day by day. "
    },
    {
        "_id": "4",
        "title": "One student one Laptop",
        "icon": faLaptopCode,
        "description": "Under the ‘One student one laptop’ project, DIU has distributed over 25000 free laptops among the student"
    },
    {
        "_id": "5",
        "title": "Blended Learning Platform",
        "icon": faChalkboardUser,
        "description": "To engage students in learning after face-to-face classes, our faculty members and students collaborate in the online"
    },
    {
        "_id": "6",
        "title": "Extra caricular activities",
        "icon": faPersonSkating,
        "description": "To ensure the mental and physical well being of our students and employees, we are providing a well-equipped gymnasiu"
    }
]

const Facilities = () => {
    return (
        <div className='my-24 container lg:mx-auto md:mx-auto mx-2 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-3 grid-flow-row '>
            <div className='col-span-2'>
                <h1 className='text-4xl font-bold text-secondary pb-4'>What we<span className='text-primary'> Offer</span></h1>
                <p>At <span className='text-primary font-bold'>Imperial College of Engineering</span>, students get the opportunity to think, learn and grow.</p>

                <div className='flex flex-wrap gap-x-12'>
                    {
                        facilitiesData.map(data => <FacilitiesCard key={data._id} data={data} />)
                    }
                </div>
            </div>
            <div className='hidden lg:block md:block'>
                <img className='h-full w-fit' src={building} alt="campus" />
            </div>
        </div>
    );
};

export default Facilities;