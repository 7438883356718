import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

const Message = () => {
    const { person } = useParams()
    const [article, setArticle] = useState();
    useEffect(() => {
        fetch('https://fahimahammed.github.io/test-data/data/administrationData.json')
            .then(res => res.json())
            .then(data => setArticle(data.find(item => item.slug === person)))
    }, [person])
    // console.log(article)
    // const {name, designation, qualification, message, image} = article;
    return (
        <div>
            <div className='page-header h-72'>
                <h1 className='text-3xl md:text-5xl text-white font-bold text-center uppercase'>HON'BLE {article?.designation} PROFILE</h1>
            </div>
            <div className='container mx-auto text-center my-12'>
                <img className='mx-auto w-32 my-4' src={article?.image} alt="Message" />
                <h3 className='text-xl font-bold text-primary'>{article?.name}</h3>
                <p>{article?.designation}</p>
                <h3 className='mt-8 mb-4 text-xl font-bold underline text-secondary'>Message from <span className='text-primary'>{article?.designation}</span></h3>
                <p className='text-justify mx-2 sm:mx-20'>{article?.message}</p>
            </div>
        </div>
    );
};

export default Message;