import React from 'react';

const NotFound = () => {
    return (
        <div className='my-12 md:my-24 flex justify-center'>
            <div>
                <img className='w-56 md:w-96' src="https://miro.medium.com/max/1400/1*zE2qnVTJehut7B8P2aMn3A.gif" alt="404"></img>
                <h1 className="text-primary text-3xl text-center">Page not found!</h1>
            </div>
        </div>
    );
};

export default NotFound;