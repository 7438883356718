import React from 'react';
import { faBuildingColumns, faGraduationCap, faPeopleGroup, faPuzzlePiece } from '@fortawesome/free-solid-svg-icons'
import LinkCard from './LinkCard';

const cardData = [
    {
        "_id": "1",
        "icon" : faBuildingColumns,
        "title" : "Admission",
        "path" : "admission"
    },
    {
        "_id": "2",
        "icon" : faGraduationCap,
        "title" : "Programs",
        "path" : "programs"
    },
    {
        "_id": "3",
        "icon" : faPeopleGroup,
        "title" : "Students Corner",
        "path" : "student-corner"
    },
    {
        "_id": "4",
        "icon" : faPuzzlePiece,
        "title" : "Activities",
        "path" : "club-organization"
    }
]

const LinkCards = () => {
    return (
        <div className='flex flex-wrap justify-center container mx-auto gap-x-8 gap-y-8 my-24'>
            {
                cardData.map(data => <LinkCard key={data._id} data={data}></LinkCard>)
            }
        </div>
    );
};

export default LinkCards;