import React from 'react';
import AdmissionBanner from './AdmissionBanner';
import AdmissionCircular from './AdmissionCircular';
import Programs from './Programs';

const Admission = () => {
    return (
        <div>
           <AdmissionBanner/>
           <AdmissionCircular/>
           <Programs/>
        </div>
    );
};

export default Admission;