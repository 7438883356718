import React, { useEffect, useState } from 'react';
import LabCard from './LabCard';

const ModernLab = () => {
    const [labs, setLabs] = useState([]);
    useEffect(() => {
        fetch('https://fahimahammed.github.io/test-data/data/labdata.json')
            .then(res => res.json())
            .then(data => setLabs(data));
    }, [])
    return (
        <div>
            <div className='page-header h-72'>
                <h1 className='text-3xl md:text-5xl text-white font-bold text-center uppercase'>Available lab & workshop at ICE</h1>
            </div>
            <div className='flex flex-wrap gap-8 justify-center container mx-auto my-12'>
                {
                    labs && labs.map(lab => <LabCard key={lab._id} lab={lab} />)
                }
            </div>
        </div>
    );
};

export default ModernLab;