import { faBell, faBox, faCreditCard, faDownload, faHandPointer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';

const AdmissionCircular = () => {
    const [dates, setDates] = useState({});
    useEffect(()=>{
        fetch('https://fahimahammed.github.io/test-data/data/admission.json')
        .then(res => res.json())
        .then(data => setDates(data));
    },[])
    return (
        <div>
            <h1 className='text-3xl text-secondary font-bold text-center my-12'>Admission <span className='text-primary'>notice</span></h1>
            <div className='text-center'>
                <h1 className='text-2xl font-bold text-secondary'>Undergraduate Admission Test 2021-22</h1>
                <div className='flex flex-wrap justify-center my-4 gap-12'>
                    <div className='flex flex-col justify-center items-center'>
                        <div className='w-48 h-48 bg-primary flex justify-center items-center'>
                            <h1 className='border-2 border-white border-dotted text-white w-40 h-40 text-2xl flex justify-center items-center font-bold'>{dates?.appyLastDate}</h1>
                        </div>
                        <h1 className='text-xl'>Last Date of Application</h1>
                    </div>
                    <div className='flex flex-col justify-center items-center'>
                        <div className='w-48 h-48 bg-primary flex justify-center items-center'>
                            <h1 className='border-2 border-white border-dotted text-white w-40 h-40 text-2xl flex justify-center items-center font-bold'>{dates?.admissionTest}</h1>
                        </div>
                        <h1 className='text-xl'>Admission Test Date</h1>
                    </div>
                </div>
                <a href="https://aca.ru.ac.bd/storage/notices/Admission_Notice_20220818.pdf" className='btn btn-primary text-white my-4'><FontAwesomeIcon icon={faDownload} />  Download Admission Circular</a>
            </div>

            <div className='flex flex-wrap gap-8 justify-center my-12'>
                <a href="https://aca.ru.ac.bd/login" target="_blank" rel="noreferrer">
                    <div className='rounded-lg w-48 h-48 flex flex-col justify-center items-center shadow-2xl hover:border hover:border-primary'>
                        <FontAwesomeIcon className='text-primary p-4 border-4 border-primary rounded-full w-16 h-16' icon={faHandPointer} />
                        <h1 className='font-bold pt-2'>Apply Online</h1>
                    </div>
                </a>
                <a href="https://aca.ru.ac.bd/site/admission-notice">
                    <div className='rounded-lg w-48 h-48 flex flex-col justify-center items-center shadow-2xl hover:border hover:border-primary'>
                        <FontAwesomeIcon className='text-primary p-4 border-4 border-primary rounded-full w-16 h-16' icon={faBell}/>
                        <h1 className='font-bold pt-2'>Admission Notice</h1>
                    </div>
                </a>
                <a href="https://aca.ru.ac.bd/site/application-guideline">
                    <div className='rounded-lg w-48 h-48 flex flex-col justify-center items-center shadow-2xl hover:border hover:border-primary'>
                        <FontAwesomeIcon className='text-primary p-4 border-4 border-primary rounded-full w-16 h-16' icon={faBox}/>
                        <h1 className='font-bold pt-2'>Application Guideline</h1>
                    </div>
                </a>
                <a href="https://aca.ru.ac.bd/site/payment-info" target="_blank" rel="noreferrer">
                    <div className='rounded-lg w-48 h-48 flex flex-col justify-center items-center shadow-2xl hover:border hover:border-primary'>
                        <FontAwesomeIcon className='text-primary p-4 border-4 border-primary rounded-full w-16 h-16' icon={faCreditCard} />
                        <h1 className='font-bold pt-2'>Payment Instruction</h1>
                    </div>
                </a>
                
            </div>

        </div>
    );
};

export default AdmissionCircular;