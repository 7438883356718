import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';


const Header = () => {
    return (
        <div>
            <div className="carousel w-full">
                <div id="slide1" className="carousel-item relative w-full h-[32rem] c-banner1">
                    {/* <img src={banner} className="w-full" /> */}
                    <div className="container mx-auto flex items-center">
                        <div className="p-12">
                            <h1 className='text-5xl text-neutral py-4 font-bold'>The Right Choice for <span className='text-primary'>Bright</span> Future</h1>
                            <p className='text-xl text-neutral'>Rajshahi University affiliated Engineering College</p>
                            <Link to="/about-us"><button className="btn rounded-full my-4">Learn More</button></Link>
                        </div>
                    </div>
                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide4" className="btn btn-ghost text-neutral">❮</a>
                        <a href="#slide2" className="btn btn-ghost text-neutral">❯</a>
                    </div>
                </div>
                <div id="slide2" className="carousel-item relative w-full h-[32rem] c-banner2">
                    {/* <img src={banner} className="w-full" /> */}
                    <div className="container mx-auto flex items-center">
                        <div className="p-12">
                            <h1 className='text-5xl text-neutral py-4 font-bold'><span className='text-primary'>Imperial</span> College of Engineering</h1>
                            <p className='text-xl text-neutral'>Rajshahi University affiliated Engineering College</p>
                            <Link to="/about-us"><button className="btn rounded-full my-4">Learn More</button></Link>
                        </div>
                    </div>
                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide1" className="btn btn-ghost text-neutral">❮</a>
                        <a href="#slide3" className="btn btn-ghost text-neutral">❯</a>
                    </div>
                </div>
                <div id="slide3" className="carousel-item relative w-full h-[32rem] c-banner3">
                    {/* <img src={banner} className="w-full" /> */}
                    <div className="container mx-auto flex items-center">
                        <div className="p-12">
                            <h1 className='text-5xl text-neutral py-4 font-bold'>Leading Private Engineering College</h1>
                            <p className='text-xl text-neutral'> ICE is a gateway to get an international quality public university degree to be a skilled and qualified engineer.</p>
                            <Link to="/about-us"><button className="btn rounded-full my-4">Learn More</button></Link>
                        </div>
                    </div>
                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide2" className="btn btn-ghost text-neutral">❮</a>
                        <a href="#slide4" className="btn btn-ghost text-neutral">❯</a>
                    </div>
                </div>
                <div id="slide4" className="carousel-item relative w-full h-[32rem] c-banner4">
                    {/* <img src={banner} className="w-full" /> */}
                    <div className="container mx-auto flex items-center">
                        <div className="p-12">
                            <h1 className='text-5xl text-neutral py-4 font-bold'>Right choice for bright future</h1>
                            <p className='text-xl text-neutral'>Rajshahi University affiliated Engineering College</p>
                            <Link to="/about-us"><button className="btn rounded-full my-4">Learn More</button></Link>
                        </div>
                    </div>
                    <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                        <a href="#slide3" className="btn btn-ghost text-neutral">❮</a>
                        <a href="#slide1" className="btn btn-ghost text-neutral">❯</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;