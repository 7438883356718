import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <div className='container mx-auto pt-4 pb-16'>
            <h1 className='text-3xl font-bold text-secondary text-center pb-4'>ABOUT</h1>
            <p className='mx-2 lg:mx-20'>Rajshahi University Affiliated Engineering College, Code: 385 Imperial College of Engineering got approval for affiliation on 18th October 2017 from a famous public university of The govering-body. ICE is a gateway to get an international quality public university degree to be a skilled and qualified engineer. As the education quality will be verified and evaluated by a public university and finally they will provide the certifi cation, the value of degree will be better than any other private university.</p>
            <div className='text-center my-4'>
                <Link to="/about-us"><button className='btn btn-outline btn-primary rounded-full'>Learn more</button></Link>
            </div>
        </div>
    );
};

export default About;