import React, { useState } from 'react'
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import './GoverningBody.css';


const GoverningBody = () => {

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <div className='container mx-auto my-4'>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.js">
                <div style={{ height: '750px' }}>
                    <Viewer
                        fileUrl={`${process.env.PUBLIC_URL}/governing-body.pdf`}
                        plugins={[
                            defaultLayoutPluginInstance,
                        ]}
                    />
                </div>
            </Worker>

        </div>
    );
};

export default GoverningBody;