import React from 'react';
import { Link } from 'react-router-dom';

const NewsNoticeCard = ({ item }) => {
    return (
        <tr className='shadow-inner rounded-lg'>
            <td className=''>
                <p>{item.title}</p>
                <p>{item.description}</p>
                <Link to={`/details/${item.type}/${item._id}`} className='underline text-primary'>View details</Link>
            </td>
            <td> </td>
            <td className='font-bold'>{item.date}</td>
        </tr>
    );
};

export default NewsNoticeCard;