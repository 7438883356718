import React from 'react';

const AboutUs = () => {
    return (
        <div>
            <div className='page-header h-72'>
                <h1 className='text-3xl md:text-5xl text-center text-white font-bold my-12'>AT A GLANCE</h1>
            </div>

            <div className='container mx-auto text-justify mb-16 mt-12'>
                <div className='text-center mb-8'>
                    <h1 className='text-xl font-bold text-primary'>Imperial College of Engineering</h1>
                    <p className='text-secondary'>Rajshahi University Affiliated Engineering College (College Code: 385) </p>
                </div>
                <div className='mx-8'>
                    <div>
                        <p>Imperial College of Engineering got approval for affiliation on 18th October 2017 from a famous public university in the country "Rajshahi University". The governing body of ICE (Imperial College of Engineering has established the college to spread quality engineering education among the students from the southern area of Bangladesh. Providing quality engineering education is the focus of ICE. Imperial College of Engineering (ICE) has been established in the industrial area Khalishpur of Khulna division, with a view to expecting to help the students of this land to fulfill their dream to be successful engineers. ICE is a gateway to getting an international quality public university degree to be a skilled and qualified engineer. As the education quality will be verified and evaluated by a public university and ally they will provide the certification, the value of a degree will be better than any other private university in Khulna.</p>
                        <p className='my-4'>Imperial College of Engineering started off with Computer Science and Engineering department, Electrical and Electronics Engineering department, and Civil Engineering department for the session 2017-2018. But with the demand for more departments, we have started with 5 disciplines from session 2018-2019 onwards.</p>
                    </div>
                    <p className='text-xl font-bold pt-4'>We provide Bachelor of Science degrees for-</p>
                    <ol className='list-decimal ml-1 md:ml-8 mt-2 mb-4'>
                        <li>B.Sc. in Computer Science & Engineering; 4-year program affiliated with Rajshahi University.</li>
                        <li>B.Sc. in Electrical & Electronic Engineering; 4-year program affiliated with Rajshahi University.</li>
                        <li>B.Sc. in Textile Engineering; 4-year program affiliated with Rajshahi University.</li>
                        <li>B.Sc. in Civil Engineering; 4-year program affiliated with Rajshahi University.</li>
                        <li>B.Sc. in Mechanical Engineering; 4-year program affiliated with Rajshahi University.</li>
                    </ol>
                    <h1 className='text-xl font-bold mt-8 mb-2'>Governing-Body</h1>
                    <p>Imperial College of Engineering is being directed by a group of experienced engineers and mentors from reputed public universities like KU, KUET & Rajshahi University.</p>
                    <h1 className='text-xl font-bold mt-8 mb-2'>Special features of Imperial College of Engineering</h1>
                    <ol className='list-disc ml-4 md:ml-8'>
                        <li>Excellent spacious campus with all modem facilities & state of art design</li>
                        <li>Highly qualified full-time faculty members & adjunct faculty members from KUET RUET, KU, RU</li>
                        <li>Sufficient well equipped Lab & workshop to ensure practical learning</li>
                        <li>Air-conditioned rich Library with free book issuing facility</li>
                        <li>Air-Conditioned Labs & Class Room with audio-visual system</li>
                        <li>Interactive teaching method with multimedia system</li>
                        <li>Free High-Speed WiFi facility </li>
                        <li>Lab & Workshop always open to practice (Office time)</li>
                        <li>Separate Hostel facility for male/female students</li>
                        <li>Industrial visit workshop, seminar, conference arrangement</li>
                        <li>Sports & Cultural activities</li>
                        <li>Completely politics free & such type of any activity prohibited</li>
                        <li>Very reasonable tuition fees structure and easily affordable cost than facilities</li>
                        <li>Scholarship & Tuition fee waiver based on SSC-HSC/Diploma Result</li>
                        <li>Various co-curricular activities</li>
                        <li>Several Clubs & organizations for skill development</li>
                        <li>Job placement cell & Industrial linkage to ensure campus placement</li>
                        <li>English Language club and mandatory English practice on campus</li>
                        <li>Excellent cafeteria & students lobby with indoor games, TV Free WiFi</li>
                        <li>All-time electricity supply with 2 standby generators</li>
                        <li>Secure campus with more than 100 CC cameras and 24X7 security guard</li>
                        <li>Well maintained rules-regulation</li>
                    </ol>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
