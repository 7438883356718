import React, { useEffect, useState } from 'react';
import EventCard from './EventCard';

const eventData = [
    {
        "_id": "1",
        "title": "SEMINAR",
        "img": "https://static.youthop.com/uploads/sites/2/2019/09/event-cover-1038x584.jpg",
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse.",
        "time": "10.00 AM",
        "date": "22-08-2022",
        "regStatus": true
    },
    {
        "_id": "2",
        "title": "WEBINAR",
        "img": "https://static.youthop.com/uploads/sites/2/2019/09/event-cover-1038x584.jpg",
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse.",
        "time": "10.30 AM",
        "date": "12-05-2022",
        "regStatus": false
    },
    {
        "_id": "3",
        "title": "CAREER TALK",
        "img": "https://static.youthop.com/uploads/sites/2/2019/09/event-cover-1038x584.jpg",
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse.",
        "time": "11.00 AM",
        "date": "10-05-2022",
        "regStatus": false
    },
    {
        "_id": "4",
        "title": "VOLUNTARY WORK",
        "img": "https://static.youthop.com/uploads/sites/2/2019/09/event-cover-1038x584.jpg",
        "description": "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dignissimos sequi placeat distinctio dolor, amet magnam voluptatibus eos ex vero, sunt veritatis esse.",
        "time": "04.00 PM",
        "date": "05-02-2022",
        "regStatus": false
    }
]



const Events = () => {
    const [eventData, setEventData] = useState([]);
    useEffect(()=>{
        fetch('https://fahimahammed.github.io/test-data/data/eventData.json')
        .then(res => res.json())
        .then(data => setEventData(data))
        .catch(err => console.log(err))
    },[])
    return (
        <div className='bg-accent py-16'>
            <div className='container mx-auto'>
                <h1 className='text-3xl font-bold text-secondary text-center pb-4 mb-12'>RECENT <span className='text-primary'> EVENTS</span></h1>
                <div className='flex flex-wrap gap-4 justify-center md:justify-between'>
                    {
                        eventData.map(data => <EventCard key={data._id} data={data} />)
                    }
                </div>
            </div>
        </div>
    );
};

export default Events;