import React, { useEffect, useState } from 'react';
import NewsNoticeCard from './NewsNoticeCard';


const NoticeNewsBoard = () => {
    const [type, setType] = useState('notice');
    const [data, setData] = useState([]);

    useEffect(() => {
        fetch('https://fahimahammed.github.io/test-data/data/notice.json')
            .then(res => res.json())
            .then(data => setData(data.filter(item => item.type === type)))
    }, [type])

    const selectData = (select) => {
        setType(select);
    }



    return (
        <div className='my-12 container mx-auto'>
            <div className='flex justify-center mb-8'>
                <div className="btn-group">
                    <button className={type === "notice" ? "btn btn-active rounded-l-full px-8" : "btn btn-outline rounded-l-full px-8"} onClick={() => selectData('notice')}>Notice</button>
                    <button className={type === "news" ? "btn btn-active rounded-r-full px-8" : "btn btn-outline rounded-r-full px-8"} onClick={() => selectData('news')}>News</button>
                </div>
            </div>

            <div className='border rounded-2xl border-primary border-2 mx-1 lg:mx-32 md:mx-24 p-12 h-64 lg:h-96 md:h-96 overflow-auto'>
                <div>
                    <table className='table table-compact w-fit'>
                        {
                            data && data.map(item => <NewsNoticeCard key={item._id} item={item} />)
                        }
                    </table>
                </div>
            </div>
        </div>
    );
};

export default NoticeNewsBoard;