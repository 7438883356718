import React from 'react';
import Header from '../shared/Header/Header';
import About from './About';
import Departments from './Departments';
import Events from './Events';
import Facilities from './Facilities';
import Gallery from './Gallery';
import LinkCards from './LinkCards';
import Message from './Message';
import NoticeNewsBoard from './NoticeNewsBoard';

const Home = () => {
    return (
        <div>
            <Header />
            <LinkCards />
            <About />
            <Departments />
            <Facilities />
            <Events />
            <NoticeNewsBoard />
            <Message />
            <Gallery />
        </div>
    );
};

export default Home;