import React from 'react';

const LabCard = ({ lab }) => {
    return (
        <div className="card w-72 bg-base-100 shadow-xl">
            <figure><img src={lab?.image} alt="LabImage" /></figure>
            <div className="py-2 text-center">
                <h2 className="card-title1">{lab?.name}</h2>
            </div>
        </div>
    );
};

export default LabCard;