import React, { useEffect, useState } from 'react';
import Row from './Row';

const Result = () => {
    const [resultData, setResultData] = useState([]);
    useEffect(() => {
        fetch('https://fahimahammed.github.io/test-data/data/result.json')
            .then(res => res.json())
            .then(data => setResultData(data))
    }, [])
    return (
        <div>
            <h1 className='text-xl font-bold my-4 text-center'>Result of BSc Engineering</h1>

            <div className="overflow-auto w-72 md:w-fit">
                <table className="table table-zebra w-fit text-sm md:text-md ">

                    <thead>
                        <tr>
                            <th>Result</th>
                            <th>Publication Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            resultData && resultData.map(data => <Row key={data._id} data={data} />)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Result;