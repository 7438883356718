import "./App.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Admission from "./components/Admission/Admission";
import Faculty from "./components/Faculty/Faculty";
import AboutUs from "./components/AboutUs/AboutUs";
import Contact from "./components/Contact/Contact";
import StudentCorner from "./components/StudentCorner/StudentCorner";
import Result from "./components/StudentCorner/Result";
import ExamNotice from "./components/StudentCorner/ExamNotice";
import ExamRoutine from "./components/StudentCorner/ExamRoutine";
import NewsNoticeDetails from "./components/shared/NewsNoticeDetails";
import ModernLab from "./components/Facilities/ModernLab";
import ClubOrganization from "./components/Facilities/ClubOrganization";
import ClubDetails from "./components/Facilities/ClubDetails";
import Navbar from "./components/shared/Navbar/Navbar";
import Footer from "./components/shared/Footer";
import MovingText from "./components/Home/MovingText";
import Message from "./components/Administration/Message";
import TeachersData from "./components/Administration/TeachersData";
import Departments from "./components/Home/Departments";
import NotFound from "./components/shared/NotFound";
import ScrollToTop from "./components/hooks/ScrollToTop";
import GoverningBody from "./components/Administration/GoverningBody";
import Library from "./components/Facilities/Library";
import Session from "./components/Registration/Session";
import Overview from "./components/Registration/Overview";

function App() {
  return (
    <div>
      <ToastContainer />

      <MovingText />
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/home" element={<Home/>}/> */}
        <Route path="/admission" element={<Admission />} />
        <Route path="/registration" element={<Session />} />
        <Route path="/overview" element={<Overview/>} />

        <Route path="/article/:person" element={<Message />} />
        <Route path="/governing-body" element={<GoverningBody />} />
        <Route path="/teachers/:dept" element={<TeachersData />} />
        <Route path="/faculty/:dept" element={<Faculty />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-info" element={<Contact />} />

        <Route path="/student-corner" element={<StudentCorner />}>
          <Route index element={<Result />}></Route>
          <Route path="result" element={<Result />}></Route>
          <Route path="exam-notice" element={<ExamNotice />}></Route>
          <Route path="exam-routine" element={<ExamRoutine />}></Route>
        </Route>
        <Route path="/programs" element={<Departments />} />
        <Route path="/details/:catagory/:id" element={<NewsNoticeDetails />} />
        <Route path="/modern-lab" element={<ModernLab />} />
        <Route path="/club-organization" element={<ClubOrganization />} />
        <Route path="/club-organization/:clubName" element={<ClubDetails />} />
        <Route path="/library" element={<Library />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
