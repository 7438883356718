import { faComputer, faGuitar, faShirt, faTableTennis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const ClubOrganization = () => {
    return (
        <div>
            <div className='container mx-auto my-16'>
                <h1 className='font-bold text-2xl md:text-4xl uppercase text-center'><span className='text-primary'>Clubs</span> & Organization</h1>
                <div className='my-12 flex flex-wrap gap-8 justify-center'>
                    <Link to="/club-organization/computer-club">
                        <div className='w-48 h-48 flex justify-center items-center shadow-lg rounded-2xl hover:border hover:border-primary'>
                            <div className='text-center'>
                                <FontAwesomeIcon className='text-primary p-4 border border-primary rounded-full w-8 h-8' icon={faComputer} />
                                <h1 className='text-primary text-xl py-2'>Computer Club</h1>
                            </div>
                        </div>
                    </Link>
                    <Link to="/club-organization/cultural-club">
                        <div className='w-48 h-48 flex justify-center items-center shadow-lg rounded-2xl hover:border hover:border-primary'>
                            <div className='text-center'>
                                <FontAwesomeIcon className='text-primary p-4 border border-primary rounded-full w-8 h-8' icon={faGuitar} />
                                <h1 className='text-primary text-xl py-2'>Cultural Club</h1>
                            </div>
                        </div>
                    </Link>
                    <Link to="/club-organization/textile-club">
                        <div className='w-48 h-48 flex justify-center items-center shadow-lg rounded-2xl hover:border hover:border-primary'>
                            <div className='text-center'>
                                <FontAwesomeIcon className='text-primary p-4 border border-primary rounded-full w-8 h-8' icon={faShirt} />
                                <h1 className='text-primary text-xl py-2'>Textile Club</h1>
                            </div>
                        </div>
                    </Link>
                    <Link to="/club-organization/sports-club"><div className='w-48 h-48 flex justify-center items-center shadow-lg rounded-2xl hover:border hover:border-primary'>
                        <div className='text-center'>
                            <FontAwesomeIcon className='text-primary p-4 border border-primary rounded-full w-8 h-8' icon={faTableTennis} />
                            <h1 className='text-primary text-xl py-2'>Sports Club</h1>
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default ClubOrganization;