import React from 'react';

const Programs = () => {
    return (
        <div>
            <h1 className='text-2xl font-bold text-secondary text-center mb-8 pt-8'>Offered <span className='text-primary'>Courses</span> & Intake Capacity at Our College</h1>
            <div className="overflow-x-auto md:w-fit mx-auto mb-12">
                <table className="table table-zebra w-full">
                   
                    <thead>
                        <tr className='text-primary'>
                            <th></th>
                            <th>Department</th>
                            <th>Seat</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr className='hover'>
                            <th>1</th>
                            <td>BSc. in Computer Science & Engineering</td>
                            <td>40</td>
                        </tr>
                        <tr className='hover'>
                            <th>2</th>
                            <td>BSc. in Electrical & Electronics Engineering</td>
                            <td>30</td>
                        </tr>
                        <tr className='hover'>
                            <th>3</th>
                            <td>BSc. in Textile Engineering</td>
                            <td>30</td>
                        </tr>
                        <tr className='hover'>
                            <th>4</th>
                            <td>BSc. Civil Engineering</td>
                            <td>30</td>
                        </tr>
                        <tr className='hover'>
                            <th>5</th>
                            <td>BSc. in Mechanical Engineering</td>
                            <td>30</td>
                        </tr>
                        <tr className='hover text-secondary'>
                            <th></th>
                            <th>Total Seat : </th>
                            <th>160</th>
                        </tr>
                       
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Programs;