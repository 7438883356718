import React from 'react';
import { Link } from 'react-router-dom';

const DeptCard = ({ data }) => {
    const { name, description, img, path } = data;
    return (
        // <div className="card w-96 shadow-lg shadow-primary image-full">
        //     <figure><img src={img} alt="Shoes" /></figure>
        //     <div className="card-body">
        //         <h2 className="card-title text-2xl text-secondary font-semibold">{name}</h2>
        //         <p>{description}</p>
        //         <div className="card-actions justify-end">
        //             <button className="btn btn-outline btn-primary rounded-full text-white">See more</button>
        //         </div>
        //     </div>
        // </div>
        <div className="card card-compact w-96 bg-base-100 shadow-xl rounded-none rounded">
        <figure><img src={img} alt="Dept" /></figure>
        <div className="card-body">
          <h1 className="card-title">{name}</h1>
          <p>{description}</p>
          <div className="card-actions justify-end">
            <Link to={path}><button className="border border-primary px-4 py-1 rounded bg-primary text-white hover:bg-secondary">See more</button></Link>
          </div>
        </div>
      </div>
       
    );
};

export default DeptCard;