import React from 'react';

const TeacherCard = ({ data }) => {
    return (
        <div className='flex flex-wrap p-4 gap-8 shadow-lg my-4 border w-96 justify-center sm:justify-start'>
            <img className='w-28 h-32 border-4 border-primary rounded-lg' src={data.image} alt="TeacherPhoto" />
            <div className='w-48 text-center sm:text-start'>
                <h1 className='font-bold text-primary'>{data.name}</h1>
                <div className='text-sm'>
                    <p>{data.designation}</p>
                    <p>Dept of {data.dept}</p>
                    <p>{data.qualification}</p>
                    <p>{data.email}</p>
                </div>
            </div>
        </div>
    );
};

export default TeacherCard;