import React from 'react';
import NoticeNewsBoard from '../Home/NoticeNewsBoard';

const ExamNotice = () => {
    return (
        <div className=''>
            <h1 className='text-xl font-bold my-4 text-center'>Exam Notice</h1>
            <NoticeNewsBoard/>
        </div>
    );
};

export default ExamNotice;