import React, { useEffect, useState } from 'react';
import Row from './Row';

const ExamRoutine = () => {
    const [routineData, setRoutineData] = useState([]);
    useEffect(()=>{
        fetch('https://fahimahammed.github.io/test-data/data/examroutine.json')
        .then(res => res.json())
        .then(data => setRoutineData(data))
    },[])
    return (
        <div>
            <h1 className='text-xl font-bold my-4 text-center'>Exam Routine</h1>
            <div className="overflow-auto w-72 md:w-fit">
                <table className="table table-zebra w-fit text-sm md:text-md ">

                    <thead>
                        <tr>
                            <th>Result</th>
                            <th>Publication Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            routineData && routineData.map(data => <Row key={data._id} data={data} />)
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ExamRoutine;