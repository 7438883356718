import React from "react";
import banner from "../../assets/event/12ft-by-6ft-_1_.webp";
import { Link } from "react-router-dom";

const Overview = () => {
  return (
    <div className=" min-h-screen">
      <div>
        <img src={banner} />
      </div>

      <div className="text-white bg-gradient-to-r from-purple-900 to-purple-500 p-8">
        <h2 className="text-3xl font-bold mb-4">
          Let's Code Your Career through Programming
        </h2>
        <h3 className="text-xl font-bold mb-2">Objective:</h3>
        <p className="mb-4">
          To inspire and guide students from various departments to explore
          programming as a valuable skill, offering insights into potential
          career paths and providing them with the tools and knowledge to kick
          start their journey.
        </p>
        <h3 className="text-xl font-bold mb-2">Session Overview:</h3>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Speakers:</strong>
            <ul className="list-disc list-inside pl-4">
              <li>
                Technical Speaker: An experienced software engineer and mentor,
                specializing in guiding CS students on effective learning
                strategies.
              </li>
              <li>
                Abdur Rakib, COO Programming Hero: An industry expert with 5
                years of experience at Brain Station 23 in software engineering
                and strategic management, dedicated to helping youth discover
                their ideal career path.
              </li>
            </ul>
          </li>
          <li>
            <strong>Session Topics:</strong>
            <ul className="list-disc list-inside pl-4">
              <li>
                Programming Fundamentals: An introduction to problem-solving and
                the importance of programming as a general knowledge skill for
                all career paths.
              </li>
              <li>
                Career Exploration: Beyond software engineering, exploring
                various untapped fields in the tech industry with tremendous
                career growth potential.
              </li>
              <li>
                Finding Your Path: Understanding the process of making informed
                career decisions and finding a path that aligns with your
                passions and strengths.
              </li>
            </ul>
          </li>
          <li>
            <strong>Interactive Q&A:</strong> An opportunity for students to ask
            questions to the speakers and gain valuable insights into
            programming and career choices.
          </li>
          <li>
            <strong>Exclusive Campus WhatsApp Group:</strong> Participants will
            have access to a dedicated WhatsApp group for the 21 Days of Coding
            program.
          </li>
          <li>
            <strong>21 Days of Coding:</strong>
            <ul className="list-disc list-inside pl-4">
              <li>
                Participants will engage in a 21-day coding program with a focus
                on web development or problem-solving based on group interests.
              </li>
              <li>
                Special live classes are led by expert mentors from Programming
                Hero.
              </li>
              <li>
                Top 3 participants will receive rewards, and all who complete
                the program will receive a certificate.
              </li>
              <li>
                A chance to interact with Jhankar Mahbub bhai online and ask
                questions.
              </li>
            </ul>
          </li>
        </ul>
        <h3 className="text-xl font-bold mb-2">Key Takeaways:</h3>
        <ul className="list-disc list-inside mb-4">
          <li>
            Inspire students to view programming as a fundamental skill for all
            career tracks.
          </li>
          <li>Provide insights into diverse tech career opportunities.</li>
          <li>Offer guidance on informed career decision-making.</li>
          <li>Encourage participation in the 21 Days of Coding program.</li>
        </ul>
        <h4 className="text-lg font-bold">Let’s Code_ Your Career</h4>
        <Link to="/registration">
          <button className=" bg-white text-[#21248B] font-bold px-5 py-1 mt-5 ">
            Registration
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Overview;
