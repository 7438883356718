import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ClubDetails = () => {
    const { clubName } = useParams();
    const [club, setClub] = useState({});
    useEffect(() => {
        fetch('https://fahimahammed.github.io/test-data/data/clubs.json')
            .then(res => res.json())
            .then(data => setClub((data.filter(item => item.type === clubName)[0])))
    }, [clubName])

    return (
        <div>
            <div className='page-header h-72 block'>
                <div>
                    <img className='w-24 mx-auto pb-2' src={club?.logo} alt="logo"></img>
                    <h1 className='text-3xl md:text-5xl font-bold text-white uppercase text-center'>{club?.name}</h1>
                </div>
            </div>
            <div className='my-12 container mx-auto text-justify w-72 sm:w-fit'>
                <p>{club?.description1}</p>
                
                <p>{club?.description2}</p>
                
                <p>{club?.description3}</p>

                {
                    club?.clubPhoto?.length !== 0 && <h1 className='text-center text-3xl text-secondary font-bold my-12 pt-4'>CLUB <span className='text-primary'>GALLERY</span></h1>
                }
                <div className='' style={{ columns: 4, columnGap: 1 }}>
                    {
                        club.clubPhoto && club.clubPhoto.map((img, i) => <img key={i} src={img} alt="img" />)
                    }
                </div>
            </div>
        </div>
    );
};

export default ClubDetails;



