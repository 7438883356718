import React from 'react';

const Message = () => {
    return (
        <div className='grid grid-cols-1 lg:grid-cols-5 md:grid-cols-5 container mx-auto bg-secondary p-12 rounded-2xl'>
            <div className=''>
                <img className='w-44 rounded-2xl border border-4 lg:border-[20px] border-primary' src="https://i.ibb.co/BBVTctY/person-avatar-icon-free-vector.jpg" alt="principal" />
            </div>
            <div className='lg:col-span-4 md:col-span-4 text-white text-justify'>
                <p>The major challenge for today’s engineering educational institutions is to accommodate the ever varying aspirations of the younger generation because of increasingly changing demand and development in industries. We constantly put efforts to accommodate these aspirations by fine tuning the academics of college with innovative and practical oriented teaching - learning practices along with other developmental activities. Our approach reflects the educational needs of the 21st century. We focus on our students by providing them with a world-class outcome based education and hands-on experience through research, training, and student forum activities etc. The success of our undergraduate, postgraduate & research programs is supervised by our eminent faculty, who continue to set the standard for excellence. There is continuous check on the implementation of planned academic activities with desired results in grooming our future generation for employment and for higher studies in India and abroad. A research culture has taken shape in the institute through enhanced R & D activities. We believe in continuous development and strive to carry on the best efforts and endeavors towards the benefit of the students.</p>
        <br/>
                <p className='text-primary font-bold'>Prof. Md Maruful Haque</p>
                <p>Principal, Imperial College of Engineering</p>
            </div>
        </div>
    );
};

export default Message;