import { faEnvelope, faLocationDot, faPhone, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import SocialIcons from './SocialIcons';


const Footer = () => {
    return (
        <div className='bg-secondary'>
            <footer className="footer p-10 text-base-100">
                <div>

                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d29415.180091275117!2d89.539444!3d22.843281!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x615067850024a662!2z4KaH4Kau4KeN4Kaq4KeH4Kaw4Ka_4Kef4Ka-4KayIOCmleCmsuCnh-CmnCDgpoXgpqsg4KaH4Kae4KeN4Kac4Ka_4Kao4KeA4Kef4Ka-4Kaw4Ka_4KaC!5e0!3m2!1sen!2sbd!4v1662129923968!5m2!1sen!2sbd"
                        //   width="600"
                        //   height="450"
                        frameBorder="0"
                        className='w-64 h-64 md:w-72 md:h-72 rounded-lg border-primary'
                        allowFullScreen=""
                        aria-hidden="false"
                        tabIndex="0"
                        title="map"
                    />
                </div>
                <div>
                    <span className="footer-title">Departments</span>
                    <Link to="/faculty/cse"><p className="link link-hover">Computer Science & Engineering</p></Link>
                    <Link to="/faculty/ce"><p className="link link-hover">Civil Engineering</p></Link>
                    <Link to="/faculty/te"><p className="link link-hover">Textile Engineering</p></Link>
                    <Link to="/faculty/eee"><p className="link link-hover">Electrical & Electronics Engineering</p></Link>
                    <Link to="/faculty/me"><p className="link link-hover">Mechanical Engineering</p></Link>
                </div>
                <div>
                    <span className="footer-title">Academic</span>
                    <Link to="/about-us"><p className="link link-hover">About us</p></Link>
                    <Link to="/contact-info"><p className="link link-hover">Contact</p></Link>
                    <Link to="/teachers/all"><p className="link link-hover">Teachers Profile</p></Link>
                    <Link to="/admission"><p className="link link-hover">Admission</p></Link>
                </div>
                <div>
                    <span className="footer-title">Contact Info</span>
                    <p><FontAwesomeIcon className='text-primary' icon={faLocationDot} /> Jessore Road, Boikali, Khulna</p>
                    <p><FontAwesomeIcon className='text-primary' icon={faPhoneVolume} /> 041-763509</p>
                    <p><FontAwesomeIcon className='text-primary' icon={faPhone} /> 01711383993, 01711383995</p>
                    <p><FontAwesomeIcon className='text-primary' icon={faEnvelope} /> chairman@imprial.edu.bd</p>

                    <span className="footer-title mt-8">Find us</span>
                    <SocialIcons/>
                </div>

            </footer>
            <p className='text-white text-center text-sm mt-2 pb-4 w-64 mx-auto sm:mx-0 sm:w-auto'>Copyright  &copy; {(new Date()).getFullYear()}, Imperial College of Engineering-All Rights Reserved</p>
        </div>
    );
};

export default Footer;